import { Injectable, inject } from '@angular/core';
import { startChangeQueue } from '@app/modules/payroll/views/record/utils/ytds/handsontable-helpers';
import { Datum, getRowHeaderWidth } from '@app/modules/payroll/views/record/utils/ytds/ytd-table-helpers';
import { HotTableRegisterer } from '@handsontable/angular';
import { TranslateService } from '@ngx-translate/core';
import {
    HandsontableableComponent,
    NumericEditor,
    baseSettings,
    beforePasteConvertToNumber,
    getHotInstance,
    highlightError,
    onHotChange,
    onSaveFailure,
    recalculateTableDimensions,
    saveChanges,
} from '@payroll/views/record/utils/ytds/handsontable-helpers';
import { renderCells } from '@payroll/views/record/utils/ytds/ytd-table-helpers';
import { CellChange } from 'handsontable/common';
import { ColumnSettings } from 'handsontable/settings';

@Injectable()
export class HandsOnTableView {
    beforeChange = null;
    changeQueue: CellChange[] = [];
    columns: ColumnSettings[] = [];
    dataset: Datum[];
    getHotInstance = getHotInstance;
    highlightError = highlightError;
    hotRegisterer = new HotTableRegisterer();
    numericEditor = NumericEditor;
    onSaveFailure = onSaveFailure;
    rowHeaderWidth = 100;
    rowHeaders: string[];
    saveChanges = saveChanges;
    saving = false;
    settings = baseSettings;
    beforePaste = beforePasteConvertToNumber;
    afterChange = onHotChange.bind(this);
    cells = renderCells.bind(this);
    recalculateTableDimensions = recalculateTableDimensions.bind(this);
    fullscreen = false;
    hotId: string;
    translateService = inject(TranslateService);

    constructor() {}

    setHotId(hotId: string): void {
        this.hotId = hotId;
    }

    onToggleFullscreen(): void {
        this.fullscreen = !this.fullscreen;
        this.recalculateTableDimensions();
    }

    setTemplateListeners(changeQueue: HandsontableableComponent): void {
        startChangeQueue(changeQueue);
        window.addEventListener('resize', this.recalculateTableDimensions);
    }

    removeWindowEventListeners(): void {
        window.removeEventListener('resize', this.recalculateTableDimensions);
    }

    setRowHeaderWidth(): void {
        this.rowHeaderWidth = getRowHeaderWidth(this.rowHeaders);
    }

    getColumnByName(name: string): ColumnSettings {
        return this.columns.find((c) => c.data === name);
    }
}
