import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Notification } from '@app/models/common/notification.model';
import { breakPoints } from '@app/styles/theme';
import { BadgeType } from '@humi-design-library/components/Badge/types';
import { NOTIFICATION_ICONS, NotificationCategoryType } from '../../constants/notification-icons';

/**
 * Handles displaying individual notifications
 * Allows toggling of showing more or less
 */
@Component({
    selector: 'app-notification-viewer-item',
    templateUrl: './notification-viewer-item.template.html',
    styleUrls: ['./notification-viewer-item.style.scss'],
})
export class NotificationViewerItemComponent implements OnInit {
    @Input() notification = new Notification();
    @Output() onToggleRead = new EventEmitter<Notification>();
    @Output() onToggleArchive = new EventEmitter<Notification>();
    @Output() onSendToInbox = new EventEmitter<Notification>();

    /**
     * The number of characters to show by default
     */
    readonly CUT_OFF_LENGTH = 100;

    /**
     * Whether to show the entire message in the case that the message is over CUT_OFF_LENGTH
     */
    showMore = false;

    isMobile = false;
    icon: NotificationCategoryType = 'general';
    notificationStatusBadgeType: BadgeType = 'default';

    constructor(private breakpointObserver: BreakpointObserver) {
        this.breakpointObserver
            .observe(`(max-width: ${breakPoints.lg}px)`)
            .pipe(takeUntilDestroyed())
            .subscribe((result) => (this.isMobile = result.matches));
    }

    ngOnInit(): void {
        this.icon = NOTIFICATION_ICONS[this.notification.category?.name] ?? 'general';
        this.getNotificationBadgeType();
    }

    getNotificationBadgeType(): void {
        switch (this.notification.status) {
            case 'action-required':
                this.notificationStatusBadgeType = 'warning';
                break;
            case 'completed':
                this.notificationStatusBadgeType = 'success';
                break;
            case 'unavailable':
                this.notificationStatusBadgeType = 'default';
                break;
        }
    }

    toggleShowMore(mouseEvent: MouseEvent): void {
        mouseEvent.stopPropagation();
        this.showMore = !this.showMore;
    }

    handleToggleRead(n: Notification): void {
        this.onToggleRead.emit(n);
    }

    handleArchive(n: Notification): void {
        this.onToggleArchive.emit(n);
    }

    handleSendToInbox(n: Notification): void {
        this.onSendToInbox.emit(n);
    }
}
